/**
   * Euclid
   */

/* Semibold */

@font-face {
	font-family: 'Euclid Circular A';
	src: url('https://d2va9gm4j17fy9.cloudfront.net/fonts/euclid-circular/EuclidCircularA-Semibold-WebXL.woff')
			format('woff'),
		url('https://d2va9gm4j17fy9.cloudfront.net/fonts/euclid-circular/EuclidCircularA-Semibold-WebXL.woff2')
			format('woff2'),
		url('https://d2va9gm4j17fy9.cloudfront.net/fonts/euclid-circular/EuclidCircularA-Semibold.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
}

/* Semibold Italic */

@font-face {
	font-family: 'Euclid Circular A';
	src: url('https://d2va9gm4j17fy9.cloudfront.net/fonts/euclid-circular/EuclidCircularA-SemiboldItalic-WebXL.woff')
			format('woff'),
		url('https://d2va9gm4j17fy9.cloudfront.net/fonts/euclid-circular/EuclidCircularA-SemiboldItalic-WebXL.woff2')
			format('woff2'),
		url('https://d2va9gm4j17fy9.cloudfront.net/fonts/euclid-circular/EuclidCircularA-SemiboldItalic.ttf')
			format('truetype');
	font-weight: 700;
	font-style: italic;
}

/* Medium */

@font-face {
	font-family: 'Euclid Circular A';
	src: url('https://d2va9gm4j17fy9.cloudfront.net/fonts/euclid-circular/EuclidCircularA-Medium-WebXL.woff')
			format('woff'),
		url('https://d2va9gm4j17fy9.cloudfront.net/fonts/euclid-circular/EuclidCircularA-Medium-WebXL.woff2')
			format('woff2'),
		url('https://d2va9gm4j17fy9.cloudfront.net/fonts/euclid-circular/EuclidCircularA-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}

/* Medium Italic */

@font-face {
	font-family: 'Euclid Circular A';
	src: url('https://d2va9gm4j17fy9.cloudfront.net/fonts/euclid-circular/EuclidCircularA-MediumItalic-WebXL.woff')
			format('woff'),
		url('https://d2va9gm4j17fy9.cloudfront.net/fonts/euclid-circular/EuclidCircularA-MediumItalic-WebXL.woff2')
			format('woff2'),
		url('https://d2va9gm4j17fy9.cloudfront.net/fonts/euclid-circular/EuclidCircularA-MediumItalic.ttf')
			format('truetype');
	font-weight: 500;
	font-style: italic;
}

/* Normal */

@font-face {
	font-family: 'Euclid Circular A';
	src: url('https://d2va9gm4j17fy9.cloudfront.net/fonts/euclid-circular/EuclidCircularA-Regular-WebXL.woff')
			format('woff'),
		url('https://d2va9gm4j17fy9.cloudfront.net/fonts/euclid-circular/EuclidCircularA-Regular-WebXL.woff2')
			format('woff2'),
		url('https://d2va9gm4j17fy9.cloudfront.net/fonts/euclid-circular/EuclidCircularA-Regular.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
}

/* Italic */

@font-face {
	font-family: 'Euclid Circular A';
	src: url('https://d2va9gm4j17fy9.cloudfront.net/fonts/euclid-circular/EuclidCircularA-RegularItalic-WebXL.woff')
			format('woff'),
		url('https://d2va9gm4j17fy9.cloudfront.net/fonts/euclid-circular/EuclidCircularA-RegularItalic-WebXL.woff2')
			format('woff2'),
		url('https://d2va9gm4j17fy9.cloudfront.net/fonts/euclid-circular/EuclidCircularA-RegularItalic.ttf')
			format('truetype');
	font-weight: 400;
	font-style: italic;
}

/**
   * Value Serif
   */

/* Bold */

@font-face {
	font-family: 'MongoDB Value Serif';
	font-weight: 700;
	src: url('https://d2va9gm4j17fy9.cloudfront.net/fonts/value-serif/MongoDBValueSerif-Bold.woff') format('woff'),
		url('https://d2va9gm4j17fy9.cloudfront.net/fonts/value-serif/MongoDBValueSerif-Bold.woff2') format('woff2'),
		url('https://d2va9gm4j17fy9.cloudfront.net/fonts/value-serif/MongoDBValueSerif-Bold.ttf') format('truetype');
}

/* Medium */

@font-face {
	font-family: 'MongoDB Value Serif';
	font-weight: 500;
	src: url('https://d2va9gm4j17fy9.cloudfront.net/fonts/value-serif/MongoDBValueSerif-Medium.woff') format('woff'),
		url('https://d2va9gm4j17fy9.cloudfront.net/fonts/value-serif/MongoDBValueSerif-Medium.woff2') format('woff2'),
		url('https://d2va9gm4j17fy9.cloudfront.net/fonts/value-serif/MongoDBValueSerif-Medium.ttf') format('truetype');
}

/* Normal */

@font-face {
	font-family: 'MongoDB Value Serif';
	font-weight: 400;
	src: url('https://d2va9gm4j17fy9.cloudfront.net/fonts/value-serif/MongoDBValueSerif-Regular.woff') format('woff'),
		url('https://d2va9gm4j17fy9.cloudfront.net/fonts/value-serif/MongoDBValueSerif-Regular.woff2') format('woff2'),
		url('https://d2va9gm4j17fy9.cloudfront.net/fonts/value-serif/MongoDBValueSerif-Regular.ttf') format('truetype');
}

@font-face {
	font-family: 'Akzidenz';
	font-weight: normal;
	src: url('https://d2va9gm4j17fy9.cloudfront.net/fonts/akzidgrostdreg.eot');
	/* IE9 Compat Modes */
	src: url('https://d2va9gm4j17fy9.cloudfront.net/fonts/akzidgrostdreg.eot?#iefix') format('embedded-opentype'),
		/* Pretty Modern Browsers */ url('https://d2va9gm4j17fy9.cloudfront.net/fonts/akzidgrostdreg.ttf')
			format('truetype'),
		/* Safari, Android, iOS */ url('https://d2va9gm4j17fy9.cloudfront.net/fonts/akzidgrostdreg.svg#Akzidenz')
			format('svg');
	/* Legacy iOS */
}

@font-face {
	font-family: 'Akzidenz';
	font-weight: 600;
	src: url('https://d2va9gm4j17fy9.cloudfront.net/fonts/akzidgrostdmed.eot');
	/* IE9 Compat Modes */
	src: url('https://d2va9gm4j17fy9.cloudfront.net/fonts/akzidgrostdmed.eot?#iefix') format('embedded-opentype'),
		/* IE6-IE8 */ url('https://d2va9gm4j17fy9.cloudfront.net/fonts/akzidgrostdmed.woff') format('woff'),
		/* Pretty Modern Browsers */ url('https://d2va9gm4j17fy9.cloudfront.net/fonts/akzidgrostdmed.ttf')
			format('truetype'),
		/* Safari, Android, iOS */ url('https://d2va9gm4j17fy9.cloudfront.net/fonts/akzidgrostdmed.svg#Akzidenz')
			format('svg');
	/* Legacy iOS */
}

@font-face {
	font-family: 'Akzidenz';
	font-weight: bold;
	src: url('https://d2va9gm4j17fy9.cloudfront.net/fonts/akzidgrostdmed.eot');
	/* IE9 Compat Modes */
	src: url('https://d2va9gm4j17fy9.cloudfront.net/fonts/akzidgrostdmed.eot?#iefix') format('embedded-opentype'),
		/* IE6-IE8 */ url('https://d2va9gm4j17fy9.cloudfront.net/fonts/akzidgrostdmed.woff') format('woff'),
		/* Pretty Modern Browsers */ url('https://d2va9gm4j17fy9.cloudfront.net/fonts/akzidgrostdmed.ttf')
			format('truetype'),
		/* Safari, Android, iOS */ url('https://d2va9gm4j17fy9.cloudfront.net/fonts/akzidgrostdmed.svg#Akzidenz')
			format('svg');
	/* Legacy iOS */
}

html {
	font-family: 'Euclid Circular A', 'Akzidenz', 'Helvetica Neue', Helvetica, Arial, sans-serif;
	font-family: 'Euclid Circular A', 'Akzidenz', Helvetica, Arial, sans-serif;
	font-weight: normal;
	font-style: normal;
}

*,
*:before,
*:after {
	box-sizing: border-box;
}

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
		'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

pre {
	display: block;
	max-width: 100%;
}

.App {
	font-family: sans-serif;
}

.app-wrapper {
	display: grid;
	grid-template: [header-start] 'header header' 107px [header-end body-start] 'side-nav body' auto [body-end] / auto 1fr;
	width: 100vw;
	max-width: 100%;
	height: 100vh;
	min-width: 767px;
	margin: 0px;
}

.app-header {
	grid-area: header;
	display: flex;
	align-items: center;
	gap: 16px;
	padding: 24px;
	box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
	z-index: 1;
}

.sidebar-nav {
	grid-area: side-nav;
}

.main-wrapper {
	grid-area: body;
	padding: 12px;
}

.scores-wrap {
	margin-top: 20px;
}

.review-group {
	border-bottom: 1px solid #ddd;
	margin-bottom: 15px;
	padding-bottom: 15px;
	&:last-child {
		margin-bottom: 0;
	}
	.group-header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-bottom: 1px solid #ddd;
		margin-bottom: 15px;
		padding-bottom: 15px;
		> p {
			margin-top: 0;
			color: #929292;
			font-size: 14px;
			margin: 0;
			span {
				font-weight: 700;
				color: #000;
			}
		}
	}
}

.score-row {
	display: grid;
	grid-template-columns: repeat(10, 1fr);
	gap: 16px;
}

.score-col {
	padding: 10px;
	border: 1px solid #ddd;
	text-align: center;
	border-radius: 20px;
	// aspect-ratio: 1/1;
	display: flex;
	flex-direction: column;
	justify-content: center;
	position: relative;
	overflow: hidden;
	&:before {
		content: '';
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 10px;
	}
	&.overall {
		color: #fff;
		aspect-ratio: 1/1;
		&.green {
			background-color: #5cb85c;
			border-color: #5cb85c;
		}
		&.red {
			background-color: #dc3545;
			border-color: #dc3545;
		}
		&.orange {
			background-color: #ffc107;
			border-color: #ffc107;
		}
		&:before {
			display: none;
		}
	}
	&.row-overall {
		&.green {
			color: #5cb85c;
			border-color: #5cb85c;
		}
		&.red {
			color: #dc3545;
			border-color: #dc3545;
		}
		&.orange {
			color: #ffc107;
			border-color: #ffc107;
		}
		&:before {
			// display: none;
		}
	}
	&.green {
		&:before,
		.bg {
			background-color: #5cb85c;
		}
	}
	&.red {
		&:before,
		.bg {
			background-color: #dc3545;
			// border-color: #dc3545;
		}
	}
	&.orange {
		&:before,
		.bg {
			background-color: #ffc107;
			// border-color: #ffc107;
		}
	}
	p {
		margin: 0 0 10px;
		font-size: 15px;
		position: relative;
	}
	span {
		display: block;
		font-size: 44px;
		line-height: 1em;
		font-weight: bold;
		position: relative;
	}
}

.regular-row {
	.score-col {
		padding: 15px 10px 25px;
		p {
			margin-bottom: 5px;
			font-size: 14px;
		}
		span {
			font-size: 30px;
		}
	}
}

.other {
	> p {
		margin: 0 0 20px;
		font-size: 18px;
	}
	.comments {
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		grid-gap: 16px;
		gap: 16px;
		p {
			font-weight: bold;
			margin-top: 0;
			margin-bottom: 15px;
			min-height: 4.5em;
			border-bottom: 1px solid #eee;
		}
		ul {
			margin: 0;
			padding: 0;
			list-style: none;
			li {
				margin-bottom: 5px;
				padding-left: 15px;
				position: relative;
				&:before {
					content: '•';
					position: absolute;
					left: 0;
				}
				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}
}

.dev-name {
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-bottom: 1px solid #ddd;
	margin-bottom: 15px;
	padding-bottom: 15px;
	line-height: 1em;
	span {
		color: #929292;
		font-size: 16px;
	}
	.name-wrap {
		display: flex;
		gap: 10px;
		align-items: center;
		.avatar {
			img {
				width: 60px;
				border-radius: 50%;
				vertical-align: top;
			}
		}
		a {
			color: #001e2b;
			text-decoration: none;
		}
	}
}
